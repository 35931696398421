import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appIcons, appImages } from "../../../../assets";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import CustomButton from "../../../../components/CustomButton";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import StackTemplate from "../../../../components/StackTemplate";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import InstaPostStyle from "./Style";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import { useDispatch, useSelector } from "react-redux";
import SignupDialog from "../../../auth/Signup";
import LoginDialog from "../../../auth/Login";
import BuyPlanModal from "../../../../components/BuyPlan";
import Loader from "../../../../components/Loader";
import {RandomName} from "../../../../dumyData/random_name"

const AllCounts = ({ icons, count }) => (
  <Stack direction={"row"} gap={"5px"} alignItems={"center"}>
    <img src={icons} width={20} />
    <Typography variant={"body1"} fontWeight={600} color={appColor.black}>
      {count || 0}
    </Typography>
  </Stack>
);

function FacebookPostDetails() {
  const navigate = useNavigate();
  const classes = InstaPostStyle();
  const globalClass = GlobalStyle();
  const dispatch = useDispatch();
  const { postData, userData } = useContext(AppContext);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [names, setNames] = useState(RandomName)
  console.log(postData, "fsdjkfdsfjsdfjdfjsk");

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({ open, type, message });
  };

  const handleCloseSnackbar = () => handleSnackbarVal(false, "", "");

  const handleFetchComments = async () => {
    if (postData?.comments_count <= 0) {
      handleSnackbarVal(true, "error", "This post has 0 comments");
      return;
    }

    const requiredFields =
      "from{name,id,picture.width(400).height(400)},message,permalink_url,message_tags";
    let comments = []; // Store all fetched comments
    let nextUrl = `https://graph.facebook.com/v20.0/${postData?.id}/comments?fields=${requiredFields}&access_token=${postData?.access_token}&limit=200`;

    try {
      setIsLoading(true);

      // Function to fetch comments and handle pagination
      const fetchComments = async (url) => {
        const response = await axios.get(url);
        if (response?.status === 200 || response?.status === 201) {
          const allComments = response?.data?.data || [];

          // Process the fetched comments, only including those with the `from` field
          const formattedComments = allComments
            // .filter((item) => item.from) // Filter out comments without the `from` field
            .map((item) => ({
              text: item?.message,
              name: item?.from
                ? item?.from.name
                : names[Math.floor(Math.random() * names.length)],
              userName: item?.from ? item?.from.name : item.id,
              image: item?.from ? item?.from.picture?.data?.url : "",
              authorId: item?.from?.id,
              authorUrl: `https://facebook.com/profile.php?id=<${item?.from?.id}>`,
              hashtags: item?.message?.toLowerCase().match(/#(\w+)/g) || [],
              userId: item?.from?.name || "",
              userInfo: item.from,
            }));

          // Append to the comments array
          comments = [...comments, ...formattedComments];

          // Check if there's a next page
          const nextPageUrl = response?.data?.paging?.next;
          if (nextPageUrl) {
            await fetchComments(nextPageUrl); // Fetch the next page recursively
          }
        } else {
          handleSnackbarVal(
            true,
            "error",
            "Something went wrong while fetching comments"
          );
        }
      };

      // Start fetching comments
      await fetchComments(nextUrl);

      setIsLoading(false);
      // Once all comments are fetched, update the store
      dispatch(setAppFilters({ ...filterData, allComments: comments }));


      console.log(comments, "asjksdasjhdasjhdasdjh")

      if (comments?.length > 0) {
        navigate("/facebook-giveaway/comment-filters");
      } else {
        handleSnackbarVal(true, "error", "Something went wrong");
      }

      console.log(comments, "All fetched comments");
    } catch (error) {
      setIsLoading(false);
      handleSnackbarVal(
        true,
        "error",
        "Something went wrong while fetching comments"
      );
    }
  };

  const handleCheckLogin = () => {
    if (userData) {
      handleFetchComments();
    } else {
      setOpenSignup(true);
    }
  };

  const handleClosePlanDialog = () => {
    setOpenPlanDialog(false);
  };

  return (
    <>
      <AppHeader>
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Loader isloading={isLoading} />
        <Divider />

        <Container sx={{ maxWidth: "550px !important", mt: 4 }}>
          <Box
            bgcolor={appColor.white}
            borderRadius={"20px"}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
          >
            <Stack
              p={"10px"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Avatar src={postData?.profile_picture_url} alt="Logo" />
                <Typography
                  variant={"h6"}
                  fontSize={13}
                  fontWeight={600}
                  color={appColor.black}
                >
                  {postData?.username}
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Box width={"100%"} p={"2px"} minHeight={"200px"}>
              <img
                src={
                  postData?.thumbnail_url
                    ? postData?.thumbnail_url
                    : postData?.media_url
                }
                width={"100%"}
                height={"100%"}
                style={{ borderRadius: "10px" }}
              />
            </Box>
            <StackTemplate
              stackProps={{
                justifyContent: "flex-start",
                m: "8px 0 0 8px",
                gap: "15px",
              }}
            >
              <AllCounts
                icons={appIcons?.heartBlack}
                count={postData?.like_count}
              />
              <AllCounts
                icons={appIcons?.commentBlack}
                count={postData?.comments_count}
              />
              {/* <AllCounts
                icons={appIcons?.shareBlack}
                count={postData?.like_count}
              /> */}
            </StackTemplate>
            <StackTemplate
              stackProps={{
                alignItems: "normal",
                justifyContent: "flex-start",
                m: "8px",
                gap: "5px",
              }}
            >
              <img src={appIcons.clockIcon} width={"20px"} height={"20px"} />
              <Typography variant="body1" fontSize={15}>
                Estimated time : 0-2 min
              </Typography>
            </StackTemplate>
            <StackTemplate
              stackProps={{
                justifyContent: "flex-end",
                mt: "10px",
                gap: 2,
                p: 1,
              }}
            >
              <Typography
                variant={"body1"}
                className={globalClass.raBackButton}
                onClick={() => navigate(-1)}
              >
                Back
              </Typography>
              <Box width={150} height={30}>
                {postData?.type === "single" ? (
                  <CustomButton
                    // btnText={"Connect with Instagram"}
                    btnText={"Comments"}
                    textWeight={400}
                    textSize={10}
                    iconSize={"15px !important"}
                  // handleClickBtn={handleFacebookLogin}
                  />
                ) : (
                  <CustomButton
                    btnText={"Load comments"}
                    textWeight={400}
                    textSize={10}
                    iconSize={"15px !important"}
                    handleClickBtn={handleCheckLogin}
                  />
                )}
              </Box>
            </StackTemplate>
          </Box>
        </Container>
        <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
          <AppFooter />
        </Container>
      </AppHeader>
      <SignupDialog
        open={openSignup}
        setOpen={setOpenSignup}
        openLogin={setOpenLogin}
      />
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        openSignup={setOpenSignup}
      />
      <BuyPlanModal
        open={openPlanDialog}
        handleClose={handleClosePlanDialog}
        dialogData={postData}
      />
    </>
  );
}

export default FacebookPostDetails;
