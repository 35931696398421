import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { appColor } from "../../../../constants/Colors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "row",
    justifyContent: "center",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ShowInfoDialog({ open, setOpen, dialogData }) {
  const handleClose = () => setOpen(false);

  console.log(dialogData, "Asdjkasdjasdasduasud");

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
            fontSize={25}
            mt={2}
          >
            💖 Commentaters Information
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={14}
            fontWeight={400}
            textAlign={"center"}
            fontFamily={"Roboto Flex"}
          >
            View commentater information
          </Typography>
        </BootstrapDialogTitle>

        <DialogContent style={{ padding: "0px 20px" }}>
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Avatar
              style={{ height: 50, width: 50 }}
              src={dialogData?.image || ""}
            />
          </Stack>
          <Stack mb={3} gap={1}>
            <Typography>ID : {dialogData?.userInfo?.id || "_"}</Typography>
            <Typography>Name : {dialogData?.userInfo?.name || "_"}</Typography>
            <Typography>
              First Name : {dialogData?.userInfo?.firstname || "_"}
            </Typography>
            <Typography>
              Last Name : {dialogData?.userInfo?.lastname || "_"}
            </Typography>
            <Typography>
              Middle Name : {dialogData?.userInfo?.lastname || "_"}
            </Typography>
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
