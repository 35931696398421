import React, { createContext, memo, useEffect, useState } from "react";
import {
  fetchCongratulation,
  fetchLoader,
  fetchPrivacy,
} from "../service/common";
import { updateSteps } from "../service/authentiction";
export const AppContext = createContext();

const AppProvider = (props) => {
  const [userData, setUserData] = useState(null);
  const [currentColor, setCurrentColor] = useState("");
  const [videoComments, setVideoComments] = useState([]);
  const [win, setWin] = useState([]);
  const [subs, setSubs] = useState([]);
  const [countDown, setCountDown] = useState(5);
  const [loaderData, setLoaderData] = useState(null);
  const [imageLoading, setImageLoaing] = useState(false);
  const [congratulationData, setCongratulationData] = useState(null);
  const [privacyAndTerms, setPrivacyAndTerms] = useState(null);
  const [newWinnerData, setNewWinnerData] = useState(null);
  const [tweetData, setTweetData] = useState(null);
  const [youtubeData, setYoutubeData] = useState(null);
  //instagram
  const [allInstaPosts, setAllInstaPosts] = useState(null);
  const [postData, setPostData] = useState(null);
  const [certificateData, setCertificateData] = useState({
    name: "",
    image: "",
  });

  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    getUserData();
    getLoaderData();
    getCongratulationData();
    setCurrentColor("#ffffff");
    getTermsAndPrivacy();
  }, []);

  const getUserData = () => {
    let ress = JSON.parse(localStorage.getItem("user"));
    setUserData(ress);
  };

  const getLoaderData = () => {
    fetchLoader()
      .then((res) => {
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setLoaderData(data);
        }
      })
      .catch((err) => { });
  };

  const getCongratulationData = () => {
    // setIsLoading(true);
    fetchCongratulation()
      .then((res) => {
        // setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setCongratulationData(data);
          console.log(data, "Sdseddferfefjgyusdf");
        } else {
          // handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        // handleSnackbarVal(true, "error", err?.message);
      });
  };

  const getTermsAndPrivacy = () => {
    fetchPrivacy()
      .then((ress) => {
        if (ress?.data?.status == "ok") {
          setPrivacyAndTerms(ress?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateSteps = (id, steps, service, completedCount) => {
    let payload = {
      steps,
      service,
      completedCount,
    };
    updateSteps(id, payload)
      .then((ress) => {
        if (ress?.data?.status == "ok") {
          console.log(ress.data.data, "Step updated");
        } else {
          console.log(ress.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <AppContext.Provider
        value={{
          userData,
          setUserData,
          currentColor,
          setCurrentColor,
          videoComments,
          setVideoComments,
          countDown,
          setCountDown,
          win,
          setWin,
          certificateData,
          setCertificateData,
          subs,
          setSubs,
          loaderData,
          getLoaderData,
          imageLoading,
          setImageLoaing,
          congratulationData,
          setCongratulationData,
          privacyAndTerms,
          newWinnerData,
          setNewWinnerData,
          youtubeData,
          setYoutubeData,
          tweetData,
          setTweetData,
          //instagram
          allInstaPosts,
          setAllInstaPosts,
          postData,
          setPostData,
          handleUpdateSteps,
          isExist,
          setIsExist,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </>
  );
};

export default memo(AppProvider);
